<template>
  <div class="basic-form">
    <div class="left">
      <div class="head">
        <i class="el-icon-document"></i>
        基本信息
      </div>
      <div class="form-wrap">
        <el-form label-width="110px" label-position="right">
          <el-form-item label="用户名：">
            <el-input placeholder="请输入用户名" style="width: 70%"></el-input>
            <el-link :underline="false" type="primary" style="margin-left: 15px"
              >实名认证</el-link
            >
          </el-form-item>

          <el-form-item label="手机号：">
            <span>130****0000</span>
            <el-link :underline="false" style="margin-left: 15px"
              >修改绑定</el-link
            >
          </el-form-item>

          <el-form-item label="用户类型：">
            <span>企业用户</span>
          </el-form-item>

          <el-form-item label="所在地区：">
            <el-select></el-select>
            <el-select style="margin-left: 15px"></el-select>
          </el-form-item>

          <el-form-item label="简介：">
            <el-input
              style="width: 90%"
              type="textarea"
              :autosize="{ minRows: 4, maxRows: 8 }"
              placeholder="请输入内容"
            >
            </el-input>
          </el-form-item>

          <el-form-item>
            <el-button style="width: 130px" type="primary">修改资料</el-button>
            <el-button style="width: 130px">返回</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="right">
      <div class="head">
        <span>
          <i class="el-icon-trophy"></i>
          推荐产品</span
        >
        <span>
          <el-link :underline="false">更多></el-link>
        </span>
      </div>

      <div class="result-box" v-for="i in 6" :key="i">
        <div class="img">
          <img src="http://dummyimage.com/130x85/00e09e" alt="" srcset="" />
        </div>
        <div class="info">
          <div class="info-row">大型设备企业的数据可</div>
          <div class="info-row">
            <span>90</span>
            天
          </div>
          <div class="info-row">Java开发,android开发,IOS开发</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "basic-form",
};
</script>

<style lang="scss" scoped>
.basic-form {
  display: flex;
  align-items: flex-start;

  .left {
    flex: 1 1 auto;

    .head {
      font-size: 16px;
      font-weight: bold;
      vertical-align: middle;
      background: #ffffff;
      height: 60px;
      line-height: 60px;
      overflow: hidden;
      padding: 0px 25px;
      margin-bottom: 12px;
    }

    .form-wrap {
      background: #ffffff;
      overflow: hidden;
      padding: 40px 25px 25px 25px;
      min-height: 650px;
      position: relative;
    }
  }

  .right {
    flex: 0 0 auto;
    width: 325px;
    margin-left: 15px;
    background-color: #fff;
    padding: 0px 20px 20px 20px;

    .head {
      display: flex;
      justify-content: space-between;
      align-items: center;

      height: 50px;
      line-height: 50px;

      span:nth-child(1) {
        font-size: 16px;
        font-weight: bold;
      }

      span:nth-child(2) {
        font-size: 14px;
      }
    }

    .result-box + .result-box {
      padding-top: 15px;
      margin-top: 15px;
      border-top: 1px solid #eeeeee;
    }

    .result-box {
      display: flex;
      .img {
        margin-right: 10px;
      }
      .info {
        flex: 1 1 auto;
        max-width: 140px;

        .info-row {
          width: 100%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        .info-row:nth-child(1) {
          font-size: 14px;
          font-weight: bold;
        }

        .info-row:nth-child(2) {
          line-height: 24px;
          height: 24px;
          font-size: 12px;
          color: #999;
          span {
            color: #f00;
          }
        }

        .info-row:nth-child(3) {
          line-height: 24px;
          height: 24px;
          font-size: 12px;
          color: #999;
        }
      }
    }
  }
}
</style>